<template>
  <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <UploadAvatar
        v-model="form.avatar"
        :current="form.avatar"
        :class="`mb-3`"
      />

      <!-- row-1 -->
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.name"
          type="text"
          name="name"
           :label="$t('components.organizationManagement.addEdit.steps.profile.title.name')"
          rules="required"
           :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.name')"
           :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.name')"
        />

        <AppInput
          v-model="form.phone_number"
          type="tel"
          name="phone"
          rules="required"
          :label="$t('components.organizationManagement.addEdit.steps.profile.title.phoneNumber')"
        :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.phoneNumber')"
          :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.phoneNumber')"
        />
      </div>
      <!-- /row-1 -->

      <!-- row-2 -->
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.alias"
          type="text"
          name="alias"
          rules="required"
        :label="$t('components.organizationManagement.addEdit.steps.profile.title.alias')"
          infoDescription="Please provide an alternative or nickname of your organization."
         :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.alias')"
        />

        <AppInput
          v-model="form.country"
          type="richselect"
          name="country"
         :label="$t('components.organizationManagement.addEdit.steps.profile.title.country')"
          rules="required"
         :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.country')"
          value-attribute="id"
          text-attribute="name"
          :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.country')"
          :options="countries"
        />
      </div>
      <!-- /row-2 -->

      <!-- row-3 -->
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.city"
          type="richselect"
          name="city"
           :label="$t('components.organizationManagement.addEdit.steps.profile.title.city')"
          rules="required"
          :disabled="getCityDisabledState"
          :placeholder="getCityPlaceholder"
         :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.city')"
          value-attribute="id"
          text-attribute="name"
          :options="cities"
        />

        <AppInput
          v-model="form.zip_code"
          type="text"
          name="zip"
          :label="$t('components.organizationManagement.addEdit.steps.profile.title.zipCode')"
          rules="required"
          placeholder="e.g. 5020"
          :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.zipCode')"
        />
      </div>
      <!-- /row-3 -->

      <!-- row-4 -->
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.default_fleet"
          type="richselect"
          name="fleet"
          rules=""
           :label="$t('components.organizationManagement.addEdit.steps.profile.title.defaultFleet')"
         :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.defaultFleet')"
          :fetch-options="onFetchFleets"
           :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.defaultFleet')"
          hide-search-box
        />

        <AppInput
          v-model="form.organization_category"
          type="richselect"
          name="type"
         :label="$t('components.organizationManagement.addEdit.steps.profile.title.type')"
          rules="required"
         :placeholder="$t('components.organizationManagement.addEdit.steps.profile.placeHolder.type')"
          text-attribute="text"
          value-attribute="value"
           :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.type')"
          :options="getOrganizationCategories"
          hide-search-box
        />
      </div>
      <!-- /row-4 -->

      <!-- row-5 -->
      <div>
        <AppInput
          v-model="form.address"
          type="textarea"
          name="address"
         :label="$t('components.organizationManagement.addEdit.steps.profile.title.address')"
          rules="required"
          placeholder=""
         :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.address')"
        />
      </div>
      <!-- /row-5 -->

      <div class="my-4 text-sm font-bold text-gray-400" v-text="$t('components.organizationManagement.addEdit.steps.profile.subText.domain') "/>

      <!-- row-6 -->
      <div class="grid grid-cols-2 gap-4">
        <AppInput
          v-model="form.admin_domain"
          type="text"
          name="admin"
          :label="$t('components.organizationManagement.addEdit.steps.profile.title.adminDomain')"
          rules="required"
          :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.adminDomain')"
          placeholder="e.g. www.admin.example.com"
        />

        <AppInput
          v-model="form.domain"
          type="text"
          name="app"
           :label="$t('components.organizationManagement.addEdit.steps.profile.title.appDomain')"
          rules="required"
         :infoDescription="$t('components.organizationManagement.addEdit.steps.profile.infoDescription.appDomain')"
          placeholder="e.g. www.app.example.com"
        />
      </div>
      <!-- /row-6 -->

      <button type="submit" ref="submitButton" class="hidden">
         {{ $t('components.stepNavigation.save') }}
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

import { UploadAvatar } from '@/components/form'
import { getFormModel } from './index'

export default {
  name: 'OrgAddEditStep1',

  components: {
    UploadAvatar,
  },

  props: {
    countries: {
      required: true,
    },
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: getFormModel({ step: 1 }),

      cities: [],
      cityIsLoading: false,
    }
  },

  computed: {
    getOrganizationCategories: () => [
      { text: 'Testing', value: 'TESTING' },
      { text: 'Live', value: 'LIVE' },
    ],

    country() {
      return this.form.country
    },

    getCityPlaceholder() {
      if (!this.form.country) return this.$t('components.organizationManagement.addEdit.steps.profile.placeHolder.city.text1')
      if (this.cityIsLoading) return this.$t('components.organizationManagement.addEdit.steps.profile.placeHolder.city.text2')
      return this.$t('components.organizationManagement.addEdit.steps.profile.placeHolder.city.text3')
    },

    getCityDisabledState() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      return false
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          console.log('formik', this.form)
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },

    country: {
      deep: false,
      immediate: true,
      async handler(countryId) {
        // this.form.city = ''
        this.cityIsLoading = true
        this.cities = await this.$http
          .get(useEndpoints.dropdown.city(countryId))
          .then((res) => res.data.data)
          .catch((err) => console.log(err.response)) // toast?
          .finally(() => (this.cityIsLoading = false))
      },
    },
  },

  methods: {
    async onFetchFleets(query) {
      await this.$http
        .get(useEndpoints.fleet.index(), {
          params: {
            search: query,
          },
        })
        .then(({ data }) => {
          this.fleets = data?.data?.map((item) => ({
            text: item.name,
            value: item.id,
          }))
        })
        .catch((err) => {
          console.log('onFetchFleets', err, err.response)
        })

      return { results: this.fleets }
    },

    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if primaryKey is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit

      const url = this.isEditing
        ? useEndpoints.organization.update(this.primaryKey)
        : useEndpoints.organization.create()

      const method = this.isEditing ? 'PATCH' : 'POST'

      const formDataProxy = { ...this.form }

      // clear optional fields
      if (typeof this.form.avatar === 'string' || this.form.avatar == null) {
        delete formDataProxy.avatar
      }

      if (this.form.default_fleet) {
        formDataProxy.default_fleet = this.form.default_fleet
      } else {
        delete formDataProxy.default_fleet
      }

      // add optional defaults
      if (typeof this.form.port === 'undefined' || this.form.avatar == null) {
        formDataProxy.port = '8080'
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Organization ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },

    // todo: need to fix, this is not working
    onTelInvalid(e) {
      // console.log(e, this.$refs.observerRef.setErrors)
      this.$refs.observerRef.setErrors({
        phone: [e.message],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
